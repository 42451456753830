import { Injectable } from '@angular/core';

@Injectable()
export class SortService {

  constructor() { }

  sort(collections: Array<any>, index: number, parameter: string, orderType: boolean) {
    // if (parameter === 'lastOrderDate') {
    //   return collections.sort(function(x, y) {
    //     x = new Date(x[parameter]);
    //     y = new Date(y[parameter]);
    //     // return +(x > y) - +(x < y);
    //     if (x > y) {
    //       return -1;
    //     };
    //     if (x < y) {
    //       return 1;
    //     };
    //     return 0;
    //   });
    // }
    if (typeof(collections[0][parameter]) !== 'number') {
      if (orderType) {
        return collections.sort(function(x, y) {
          return +(x[parameter].toUpperCase() < y[parameter].toUpperCase()) - +(x[parameter].toUpperCase() > y[parameter].toUpperCase());
        });
      } else {
        return collections.sort(function(x, y) {
          return +(x[parameter].toUpperCase() > y[parameter].toUpperCase()) - +(x[parameter].toUpperCase() < y[parameter].toUpperCase());
          });
      }
    } else {
      if (orderType) {
        return collections.sort(function(x, y) {
          return x[parameter] - y[parameter];
        });
      } else {
        return collections.sort(function(x, y) {
          return y[parameter] - x[parameter];
        });
      }
    }
  }
}
