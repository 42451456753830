import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { FedexService } from 'app/shared/services/fedex.service';
import { States } from 'app/shared/types/aparment-complex-info';
import { OptionFedexValidation } from 'app/shared/types/location';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Fedex, ModalFedexData } from '../shared/types/fedex';

@Component({
  selector: 'app-modal-fedex',
  templateUrl: './modal-fedex.component.html',
  styleUrls: ['./modal-fedex.component.scss']
})
export class ModalFedexComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  public fedexValidation: Fedex

  public validationOptions: OptionFedexValidation[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalFedexComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ModalFedexData,
    public fedexService: FedexService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.onValidAddressByFedex();
  }

  async onValidAddressByFedex() {
    this.blockUI.start('Loading matches...')
    await this.fedexService.getAddress(this.data.addressToValidate)
      .toPromise()
      .then((result: Fedex) => {
        this.fedexValidation = result;

        if (this.fedexValidation && this.fedexValidation.warningMessage) {
          this.validationOptions.push({ text: 'Keep my data', select: false, isSuggest: false });
          return;
        }

        if (this.fedexValidation && this.fedexValidation.streetLines) {     
          this.data.addressSuggestion = this.buildAddress();
          const suggestAddress = this.fedexValidation.streetLines.length > 1 ?
            `${this.fedexValidation.streetLines[0]},
            ${this.fedexValidation.streetLines[1]},
            ${this.fedexValidation.city},
            ${this.fedexValidation.stateOrProvince},
            ${this.fedexValidation.postalCode}` :
            `${this.fedexValidation.streetLines[0]},
            ${this.fedexValidation.city},
            ${this.fedexValidation.stateOrProvince},
            ${this.fedexValidation.postalCode}`;
          this.validationOptions.push({ text: suggestAddress, select: false, isSuggest: true });          
          this.validationOptions.push({ text: 'Keep my data', select: false, isSuggest: false });
        }
      });
      
      this.blockUI.stop();
  }

  onValidationSelect(option: OptionFedexValidation) {
    this.validationOptions.map(opt => opt.select = (opt === option))

  }

  onKeepEdit() {
    this.data.modalState = 'keep-edit';
    this.dialogRef.close(this.data);
  }

  onAllowContinue(): boolean {
    return !(this.validationOptions.filter(f => f.select === true).length === 0);
  }

  onSelectOption() {
    const optionSelect = this.validationOptions.find(f => f.select === true);
    if (optionSelect !== undefined) {
      if (optionSelect.isSuggest) {
        
        this.data.modalState = 'suggested-address';
        this.data.addressSelection = this.buildAddress();
      } else {
        this.data.modalState = 'keep-address';
      }
      this.dialogRef.close(this.data);
    }
  }

private buildAddress(){
  const stateFind: States = this.data.stateList.find(f => f.abbreviation.toUpperCase() === this.fedexValidation.stateOrProvince.toUpperCase());

  return {
    addressLine1: this.fedexValidation.streetLines[0],
    addressLine2: this.fedexValidation.streetLines.length > 1 ? this.fedexValidation.streetLines[1] : '',
    addressCity: this.fedexValidation.city,
    addressStateID: stateFind.id,
    addressStateName: stateFind.name,
    addressZipCode: this.fedexValidation.postalCode.toString()
  }
}

}
