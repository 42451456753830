import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/share';

import { HttpHeaders, HttpClient } from '@angular/common/http';

import { CustomItems } from '../types/custom-items';
import { Injectable } from '@angular/core';
import { ItempriceParams } from '../types/custom-items';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';

@Injectable()
export class CustomItemsService {

  public validationsValues: any = {};
  constructor(
    private http: HttpClient
  ) {
    this.getMinimunHingSide().subscribe((data) => {
      // others values haracode from backend will be set here
      this.validationsValues = Object.assign({MinimumHingeOffset: data}, this.validationsValues);
    });
  }

  getTypes(apartmentComplexId: number): Observable<any> {
     // tslint:disable-next-line:prefer-const
     let searchParams: any = new Object();
     searchParams['apartmentComplexID'] = apartmentComplexId.toString();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}api/customitems/types`, requestOptiosn);
  }

  getTypesByColor(apartmentComplexId: number, colorId: string): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['colorId'] = colorId.toString();
    searchParams['apartmentComplexID'] = apartmentComplexId.toString();
   const headers = new HttpHeaders();
   headers.append('Content-Type', 'application/json');
   const requestOptiosn = {headers, params: searchParams};
   return this.http.get(`${environment.api}api/customitems/typesbycolor`, requestOptiosn);
 }

  getPullsNum(customItemTypeID: Object, complexId: Number): Observable<number> {
    const headers = new HttpHeaders();
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    for (const key in customItemTypeID) {
      if (customItemTypeID.hasOwnProperty(key)) {
        searchParams[key] = customItemTypeID[key];
      }
    }

    searchParams['apartmentComplexID'] = complexId.toString();
    headers.append('Content-Type', 'application/json');
    return this.http.get<number>(`${environment.api}api/customitems/numberofpulls`, {headers, params: searchParams});
  }

  getHingesNum(customItemTypeID: any): Observable<number> {
    const headers = new HttpHeaders();
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    for (const key in customItemTypeID) {
      if (customItemTypeID.hasOwnProperty(key)) {
        searchParams[key] = customItemTypeID[key];
      }
    }
    headers.append('Content-Type', 'application/json');
    return this.http.get<number>(`${environment.api}api/customitems/numberofhinges`, {headers, params: searchParams});
  }

  getCustomItemPrice( queryParams: ItempriceParams ): Observable<any> {
    const headers = new HttpHeaders();
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();

    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        searchParams[key] = queryParams[key];
      }
    }
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/customitems/itemprice`, {headers, params: searchParams});
  }

  newCustomItem(customItem: CustomItems) {
    const pullItem = customItem.pullItemNumber;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    if(customItem.pullItemNumber == null){
      customItem.pullItemNumber = pullItem;
    }
    if(customItem.pullItemNumber != null  && customItem.height != null && customItem.width != null){
      return this.http.post(`${environment.api}api/apartments/customitems`, customItem, {headers: headers});
    }
  }

  getHingeOffSet(customItem: CustomItems) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/customitems/hingeOffSet`, {headers});
  }

  editCustomItem(customItem: CustomItems) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/apartments/customitems`, customItem, {headers: headers});
  }

  deleteCustomItem(ids: Object) {
    const headers = new HttpHeaders();
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();

    for (const key in ids) {
      if (ids.hasOwnProperty(key)) {
        searchParams[key] = ids[key];
      }
    }
    searchParams['recalculate'] = 'true';
    headers.append('Content-Type', 'application/json');

    return this.http.delete(
      `${environment.api}api/apartments/customitems`, {headers, params: searchParams});
  }

  validateITems(id: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['ApartmentID'] = id.toString();

    return this.http.get(`${environment.api}api/apartments/validateselecteditems`, {headers, params: searchParams});
  }

  getMinimunHingSide(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.get(`${environment.api}api/customitems/minimumhingeside`, {headers});
  }

  getPartsOrder(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/apartments/partsorder`, {headers});
  }

    getSettings(): Observable<any>{
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(`${environment.api}api/customitems/settings`, { headers })
    }
 }
