import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class SearchService {

  constructor() { }

  search(collections: Array<any>, searchParam: string, collectionParams: Array<any>): any {
    if (collections.length !== 0) {
      const result = _.filter(collections, function(collection) {
        for (const param of collectionParams) {
          if (typeof(collection[param]) !== 'number') {
            if (_.includes(collection[param].toUpperCase(), searchParam.toUpperCase())) {
              return collection;
            }
          } else {
            if (_.includes(collection[param].toString(), searchParam)) {
              return collection;
            }
          }
        }
      });
      return result;
    }
  }
}
