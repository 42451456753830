import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  public loading: boolean;
  public show: number;
  // Variables style
  public middle: boolean;
  public radiusSize: number;
  public borderSize: number;
  public messageSize: number;

  public message: string;

  constructor() { }

  ngOnInit() {
  }
  public showSpinner(message?: string, middle?: boolean, radius?: number, border?: number, text?: number): void {
    this.message = message;
    this.middle = middle;
    this.radiusSize = radius;
    this.borderSize = border;
    this.messageSize = text;
    this.loading = true;
    this.show = 0;
  }
  public onSuccess(message: string) {
    this.show = 1;
    this.message = message;
  }
  public onFail(message: string) {
    this.show = 2;
    this.message = message;
  }
  public hideSpinner() {
    this.loading = false;
  }

}
