import { Directive, HostListener } from '@angular/core';

// Service
import { SharedService } from '../services/shared.service';

@Directive({
  selector: '[appScreenBehavior]'
})
export class ScreenBehaviorDirective {

  constructor(private sharedService: SharedService) { }

  @HostListener('window:load', []) onLoad() {
    this.sharedService.onSaveScreenSizeChange(window.innerWidth, window.innerHeight);
  }

  @HostListener('window:resize', []) onResize() {
    this.sharedService.onSaveScreenSizeChange(window.innerWidth, window.innerHeight);
  }

}
