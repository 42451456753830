import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/share';

import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { TermsAndConditionsAcceptance, UserTermsAndConditionsStatus } from '../types/user-info';

@Injectable()
export class AccountService {
    constructor(
        private http: HttpClient
      ) { }

    getTermsAndServicesAcceptance(): Observable<UserTermsAndConditionsStatus>{
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get<UserTermsAndConditionsStatus>(`${environment.api}api/account/termsandservices/agreed`, {headers});
    }

    getTermsAndServices(): Observable<any>{
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.api}api/account/termsandservices/land`, {headers});
  }

    postAcceptTermsAndConditions(acceptance: TermsAndConditionsAcceptance): Observable<any>{
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.post(`${environment.api}api/account/termsandservices/accept`, acceptance, {headers});
    }

    getcreditCardPayment(): Observable<any> {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.api}api/account/iscreditcardpayment`, { headers});
    }

    getAccountSummaryDetails(body: Array<number>): Observable<any> {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.post(`${environment.api}api/account/accountsummary`, body, { headers});
    }

    postSingInAccountSetUpPage(body: any): Observable<boolean> {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.post<boolean>(`${environment.api}api/account/singInAccountPage`, body, { headers });
    }
}