import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { ApartmentComplexService } from '../shared/services/apartment-complex.service';
import { LoginService } from '../shared/services/login.service';
import { LandingApartmentComplexService } from '../shared/services/landing-apartment-complex.service';
import { ModalDirective } from 'ngx-bootstrap';
import { AlertNotify } from '../shared/types/alert-Notify';
import { AlertService } from '../shared/services/alert.service';
import * as moment from 'moment';
import { ModalComponent } from 'app/modal/modal.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public visit = 'VISIT QWIKKIT MAIN';
  public signOut = 'Sign Out';
  public logged = false;
  public details = false;
  public navbar = true;
  public myDashboard = 'My Dashboard';
  public support = 'HELP / SUPPORT';
  public hasLedgeEntries;
  public customerLedge: any;
  public viewNotifyPanel = false;
  public isOnShoppingCart = false;
  public videoUrl: SafeResourceUrl;

  @ViewChild('childModal') public childModal: ModalDirective;
  @ViewChild('orderingVideo') orderingVideo: ModalComponent;
  @Input() notifyList: AlertNotify[] = [];

  constructor(
    private loginService: LoginService,
    private router: Router,
    private apartmentComplexService: ApartmentComplexService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private landingApartmentComplexService: LandingApartmentComplexService) {
      this.router.events.subscribe( (params ) => {
        if ( params instanceof RoutesRecognized) {
          if (params.url === '/login' || params.url === '/account1234') {
            this.details = false;
          }

          this.isOnShoppingCart = params.url.includes("shopping-cart/false");
        }
      });
    }

  ngOnInit() {
    if (this.loginService.getToken() !== false) {
      this.loginService.authenticate.next(true);
      this.checkLedgeEntries();
      this.landingApartmentComplexService.setUI(0);
    }
    this.loginService.authenticate.subscribe(
      (value: boolean) => this.logged = value,
      err => console.error(err));
    this.landingApartmentComplexService.isDetails.subscribe(
      (value: boolean) => this.details = value,
      err => console.error(err));
      this.loginService.hasLedgeEntries.subscribe(
        (value: boolean) => this.hasLedgeEntries = value,
        err => console.error(err));
  }

  logOut() {
    this.loginService.logOut();
    this.landingApartmentComplexService.isDetails.next(false);
    this.router.navigate(['/login']);
  }

  onEditAccount() {
    this.landingApartmentComplexService.isDetails.next(true);
    this.router.navigate(['/account1234/edit']);
  }

  goDashboard() {
    this.landingApartmentComplexService.isDetails.next(false);
    this.router.navigate(['/dasboard']);
  }

  goSupport() {
    window.open('https://cinchkit.freshdesk.com/support/home', '_blank');
  }

  home() {
    this.router.navigate(['/dasboard']);
  }

  openNewTab() {
    window.open('https://qwikkit.com/', '_blank');
  }

  async checkLedgeEntries() {
    try {
    this.customerLedge = await this.apartmentComplexService.getDueLeadgeEntries().toPromise();
  } catch (err) {
    console.error('Error loading leadge entries.', err);
  }
    if (this.customerLedge.length > 0) {
      this.hasLedgeEntries = true;
    } else {
      this.hasLedgeEntries = false;
    }
  }

  showNotify() {
    this.viewNotifyPanel = !this.viewNotifyPanel;
    this.childModal.config.backdrop = false;
    this.childModal.show();
  }

  async markReadNotification(alert: AlertNotify) {
    await new Promise<AlertNotify>((resolve, reject) => {
      let notifiDismiss:AlertNotify;
      alert.readBy = this.loginService.getToken().userName;
      alert.readDate = new Date(moment().format());
      this.alertService.putMarkReadAlert(alert).subscribe(
        (response: AlertNotify) => notifiDismiss = response,
        (err) => {
          console.error(err)
          reject();
        },
        () => resolve(notifiDismiss))
    }).then((alertDelete: AlertNotify) => {
      const idx = this.notifyList.findIndex(a => a.alertId === alertDelete.alertId);
      if (idx !== -1) {
        this.notifyList.splice(idx, 1);
      }
    })
  }

  async markReadAllNotification() {
    for (const alert of this.notifyList) {
      await new Promise<AlertNotify>((resolve, reject) => {
        let notifiDismiss:AlertNotify;
        alert.readBy = this.loginService.getToken().userName;
        alert.readDate = new Date(moment().format());
        this.alertService.putMarkReadAlert(alert).subscribe(
          (response: AlertNotify) => notifiDismiss = response,
          (err) => {
            console.error(err)
            reject();
          },
          () => resolve(notifiDismiss))
      }).then((alertDelete: AlertNotify) => {
        const idx = this.notifyList.findIndex(a => a.alertId === alertDelete.alertId);
        if (idx !== -1) {
          this.notifyList.splice(idx, 1);
        }
      })
    }
  }

  onPlayWatchVideo() {
    this.orderingVideo.showChildModal();
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/407828664');
  }

  payNow() {
    window.open("https://qwikkit.com/payments/", '_blank');
  }
}
