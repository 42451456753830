import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Token } from '../types/token';

@Injectable()
export class PullsService {

  constructor(
    private http: HttpClient
  ) { }

  getPulls(aptComplexId:number = 0): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/pulltypes/${aptComplexId}`, {headers: headers});
  }

  getPullList(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/apartments/listpullitems`, {headers: headers});
  }

  getAptPulls(data: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        searchParams[key] = data[key];
      }
    }
    const optionsArgs = {headers, params: searchParams};
    return this.http.get(`${environment.api}/api/apartments/pullitems`, optionsArgs);
  }

  getPullColors(): Observable<any>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/api/pulltypes/colors`, {headers: headers});
  }

  getPullStyles(colorId: number): Observable<any>{    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/api/pulltypes/styles/${colorId}`, {headers: headers});
  }

  getPullsByColor(colorId: number, styleId: number, aptComplexId: number = 0): Observable<any>{     
    let searchParams: any = new Object();
    searchParams['colorId'] = colorId;
    searchParams['styleId'] = styleId;
    searchParams['aptComplexId'] = aptComplexId;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}/api/pulls/colors/`, requestOptiosn);   
  }

  getPullGroup(pullId: number, aptComplexId:number = 0): Observable<any>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/api/pulls/colors/group/${pullId}/${aptComplexId}`, {headers: headers});
  }

  getPullStyle(pullTypeId: number): Observable<any>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/api/pulltypes/stylesbytype/${pullTypeId}`, {headers: headers});
  }
}
