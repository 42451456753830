import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Observable } from 'rxjs/Observable';
import { Token } from '../types/token';
import { environment } from '../../../environments/environment';

@Injectable()
export class OrderReviewService {
  constructor(
    private http: HttpClient,
    private loginService: LoginService,
  ) { }

  validateOrder(apartmentID: number): Observable<boolean> {
    const token: Token = this.loginService.getToken();

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${token.token_type} ${token.access_token}`);

    return this.http.post<boolean>(`${environment.api}api/orders/validateOrder?apartmentID=${apartmentID}`, {}, { headers: headers })
                    .catch(() => Observable.of(false));
  }

  getOrderReview(id: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/orders/${id}/orderreview`, { headers: headers });
  }

  getAcknowledgment(id: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.get(`${environment.api}api/orders/${id}/acknowledgment`, { headers: headers });
  }

  deleteOrderItem(apartmentID: string, itemID: string, itemType: number) {
    const headers = new HttpHeaders();
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    headers.append('Content-Type', 'application/json');

    let endpoint;
    if (itemType === 1) {
      endpoint = 'customitems';
      searchParams['customItemID'] = itemID;
      searchParams['recalculate'] = 'false';
    } else {
      endpoint = 'standarditems';
      searchParams['standardItemID'] = itemID;
    }
    searchParams['apartmentID'] = apartmentID;

    return this.http.delete(`${environment.api}api/apartments/${endpoint}`, { headers, params: searchParams });
  }

  syncOrder(apartment: Number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/orders/syncorder?apartmentID=` + apartment, {}, { headers: headers });
  }

  getTaxrate(id: number) {
    const headers = new HttpHeaders();
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    headers.append('Content-Type', 'application/json');
    searchParams['aparmentID'] = id.toString();
    headers.append('apartmentID', `${id}`);
    return this.http.get(`${environment.api}api/orders/taxrate`, { headers: headers, params: searchParams });
  }

  sendAcknowledgmentPDF(base64PDF: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return this.http.post(`${environment.api}api/orders/sendacknowledgment`, base64PDF,
      { headers: headers });
  }

  CheckDoorsHinges(aptID: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/apartments/${aptID}/checkdoorshinges`, {headers: headers});
  }

  CheckPanelPull(aptID: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/apartments/${aptID}/checkpanelpull`, {headers: headers});
  }

  GetOrdersForResend(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/orders/ordersforresend`, {headers: headers});
  }

  ResendOrders(order): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/orders/resendorder`, order, {headers: headers});
  }
}
