import { Injectable } from '@angular/core';

@Injectable()
export class ServerStatusService {
  constructor() { }

  status(code: number, message: any): string | null {  
    switch (code) {
      case 400:
        return message.error_description;
      case 401:
        return 'Your session has expired, please re-sign in.';
      case 403:
        return 'Your session has expired, please re-sign in.';
      case 402:
        return message;
      case 404:
        return 'Not Found.';
      default:
        return null;
    }
  }
}
