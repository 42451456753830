import { Injectable } from '@angular/core';
import * as moment from 'moment';


@Injectable()
export class DateParserService {

  constructor() { }

  dateParser(date: string, format: string): string {
    return moment(date).format(format);
  }
}
