import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule} from 'ngx-bootstrap/modal';

import { SpinnerComponent } from '../spinner/spinner.component';
import { ModalComponent } from '../modal/modal.component';
import { SanitizerPipe } from './pipes/sanitazer.pipe';
import { ReorderModalComponent } from './../reorder-modal/reorder-modal.component';
import { TrackingModalComponent } from './../dashboard/trackingModal/tracking-modal.component';
import { LedgeModalComponent } from './../ledgeModal/ledge-modal.component';
import { FormsModule } from '@angular/forms';
import { TokenInterceptor } from './token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrderByPipe } from 'app/shared/pipes/order-by.pipe';
import { FilterPipe } from 'app/shared/pipes/filter.pipe';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ModalModule.forRoot()
  ],
  declarations: [ SpinnerComponent, ModalComponent, SanitizerPipe, ReorderModalComponent, TrackingModalComponent, LedgeModalComponent, OrderByPipe, FilterPipe],
  exports: [ SpinnerComponent, ModalComponent, SanitizerPipe, ReorderModalComponent, TrackingModalComponent, LedgeModalComponent, OrderByPipe, FilterPipe ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class SharedModule { }
