import { Login } from '../types/login';
import { Credentials } from '../types/credentials';

export const LoginValues: Login = {
  welcomeTitle : 'Welcome!',
  loginTxt : 'Log In',
  forgotPass : 'Forgot your password?',
  sign : 'Sign In',
  registerTxt : 'Create an Account',
  registerDesc : `
  To create an account, please contact your Qwikkit sales rep or call us at 844-4-THE-KIT.`,
  beginOrder : 'Begin an Order',
  createAccount : 'Create an Account Now',
  continue : 'CREATE ACCOUNT',
  ledger: 'Customer Ledger Entries',
  setUp : 'Set up an account',
  homeOwnerTitle: 'If you are a homeowner',
  homeOwnerDesc: 'Please visit our retail site tailored to your needs.',
  homeOwnerTitleBtn: 'KitchenMakeover.com'
};
