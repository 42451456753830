export class Apartment {
  constructor(
    public id: number,
    public apartmentComplexID: number,
    public apartmentComplexName: string,
    public apartmentNumber: string,
    public apartmentDescription: string,
    public notes: string,
    public apartmentTotal: number,
    public createdDate: string,
    public modifiedDate: string,
    public customerPONumber: string,
    public orderNumber: string,
    public orderDate: string,
    public orderStatus: string,
    public isArchived: boolean,
    public status: number,
    public apartmentType: string,
    public isAbleEdit:boolean,
    public partsOrder?: boolean,
    public orderStatusID?: number,
    public isQuote?: boolean,
    public shoppingCartUserId?: string,
    public submitUserName?: string,
    //NoChargeOrder
    public noChargeReasonCodeId?: number,
    public applyNoCharge?: boolean,
    public noChargeOriginalOrderNumber? : number
  ) {}
}

export class pullItemQuantity {
  constructor(
    public itemNumber: string,
    public quantity: number,
  )
  {}
}
