import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Token } from '../types/token';

@Injectable()
export class KitOptionsService {
  constructor(
    private http: HttpClient
  ) { }

  getKitOptions(doorTypeId: string): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['doorTypeId'] = doorTypeId;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}api/kitOptions/kitOption`, requestOptiosn);
  }

  getKitOptionsByApartmentComplexId(apartmentComplexId: number) {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentComplexId'] = apartmentComplexId;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}api/hinges/kitOptionsByApartmentComplexId`, requestOptiosn);
  }
}
