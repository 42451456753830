import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { LoginService } from './login.service';
import { Token } from '../types/token';
import { environment } from '../../../environments/environment';
import { AparmentComplex } from '../types/apartment-complex';
import { NotificationContact } from '../types/notitication-contact-info';

@Injectable()
export class ApartmentComplexService {

  constructor(
    private http: HttpClient
  ) { }

  getApartmentComplex(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/complexes`, {headers: headers});
  }

  getAparmentComplexByID(id: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<AparmentComplex>(`${environment.api}api/complexes/${id}`, {headers: headers});
  }

  getDefaultsID(id: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<AparmentComplex>(`${environment.api}api/complexes/defaults/${id}`, {headers: headers});
  }

  getAptComplexBillingAddress(id: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/complexes/${id}/billingaddress`, {headers: headers});
  }

  getCustomItems(id: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/apartments/${id}/customitems`, {headers: headers});
  }

  validApartment(apartmentNumber, apartmentComplexID, apartmentId): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http
      .get(`${environment.api}api/apartments/validateapartment`, {
        headers, params: {apartmentId, apartmentNumber, apartmentComplexID}
      });
  }

  validateRequierePONumber(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/apartments/isporequired`, {headers: headers});
  }

  getTrackingDetail(orderNumber): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http
      .get(`${environment.api}api/apartments/gettrackingdetail`, {
        headers, params: {orderNumber}
      });
  }

  getAvailableCredit(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/creditlimit`, {headers: headers});
  }

  getAmountBalanceLimitAlert(): Observable<number> {
    return this.http.get<number>(`${environment.api}api/account/amountBalanceLimitAlert`);
  }

  getLeadgeEntries(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/leadgeEntries`, {headers: headers});
  }

  getAllInvoices(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/invoices`, {headers: headers});
  }

  getAllInvoicesPaged(page, pageSize, searchText: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/invoicesPaged`, {headers: headers, params:{page, pageSize, searchText}});
  }

  getDueLeadgeEntries(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/orders/dueleadgeEntries`, {headers: headers});
  }

  getShoppingCartGroup(apartmentId): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/orders/getShoppingCartGroup`, {headers, params: {apartmentId}});
  }

  createNotificationContact(body: NotificationContact, apartmentComplexId): Observable<any>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/complexes/addnotification`, body, {headers:headers, params: {apartmentComplexId}});
  }

  modifyNotificationContact(body: NotificationContact): Observable<any>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/complexes/editnotification`, body, {headers:headers});
  }

  deleteNotificationContact(notificationId: any): Observable<any>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(`${environment.api}api/complexes/deletenotification`, {headers: headers, params:{notificationId}})
  }

  getNotificationContact(complexId: number): Observable<Array<NotificationContact>>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<Array<NotificationContact>>(`${environment.api}api/complexes/getnotification/${complexId}`, {headers: headers})
  }

  hasUserResponded(complexId: number): Observable<boolean>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<boolean>(`${environment.api}api/complexes/has-user-responded/${complexId}`, {headers: headers})
  }

  registerUserResponse(apartmentComplexId): Observable<any>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/complexes/register-response`, {}, {headers:headers, params: {apartmentComplexId}});
  }
}