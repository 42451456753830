import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { environment } from 'environments/environment';
import { HubConnectionConfig } from '../types/signalr';
import { PackagePlanStatusMessage } from '../types/package-plan-status-message';
import { IntegrationNavAccountStatusMessage } from '../types/integration-navaccount-status-message';

const RECONNECTION_TIMEOUT = 10000;

/**
 * SignalrService
 */
@Injectable()
export class SignalrService {
  _hubConnections: Array<HubConnection> = [];

  /**
   * starting$ is an observable available to know if the signalr
   * connection is ready or not. On a successful connection this
   * stream will emit a value.
   */
  starting$: Observable<any>;
  /**
   * connectionState$ provides the current state of the underlying
   * connection as an observable stream.
   */
  orderHubConnectionState$: Observable<HubConnectionState>;
  orderHubConnectionState: HubConnectionState;

  integrationHubConnectionState$: Observable<HubConnectionState>;
  integrationHubConnectionState: HubConnectionState;
  /**
   * error$ provides a stream of any error messages that occur on the
   * SignalR connection
   */
  error$: Observable<string>;

  /**
   * error$ provides a stream of any status messages that occur on the
   * SignalR connection
   */
  statusMessage: Observable<any>;
  statusMessageIntegration: Observable<any>;

  // These are used to feed the public observables
  //
  private orderHubConnectionStateSubject = new Subject<HubConnectionState>();
  private integrationHubConnectionStateSubject = new Subject<HubConnectionState>();
  private startingSubject = new Subject<any>();
  private errorSubject = new Subject<any>();
  private statusMessageSubject = new Subject<PackagePlanStatusMessage>();
  private statusMessageIntegrationSubject = new Subject<IntegrationNavAccountStatusMessage>();

  constructor() {
    // Set up our observables
    //
    this.orderHubConnectionState$ = this.orderHubConnectionStateSubject.asObservable();
    this.integrationHubConnectionState$ = this.integrationHubConnectionStateSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
    this.starting$ = this.startingSubject.asObservable();
    this.statusMessage = this.statusMessageSubject.asObservable();
    this.statusMessageIntegration = this.statusMessageIntegrationSubject.asObservable();

    try {
      this.setUpHubConnections();
    } catch (err) {
      console.error('Error trying to set up hub connections', err);
      this.errorSubject.next(err);
    }
  }

  setUpHubConnections() : void {
    let hubConnectionConfigs: Array<HubConnectionConfig> = [
      { 
        hubName: "orderHub",
        connectionState: this.orderHubConnectionState,
        connectionStateSubject: this.orderHubConnectionStateSubject,
        hubMethodHandlers: [
          { methodName: "BroadcastPingMessage", methodHandler: console.log },
          { methodName: "BroadcastStatusMessage", methodHandler: (message: any) => { this.statusMessageSubject.next(message); }}
        ] 
      },
      { 
        hubName: "integrationHub",
        connectionState: this.integrationHubConnectionState,
        connectionStateSubject: this.integrationHubConnectionStateSubject,
        hubMethodHandlers: [
          { methodName: "BroadcastPingMessage", methodHandler: console.log },
          { methodName: "BroadcastIntegrationStatusMessage", methodHandler: (message: any) => { this.statusMessageIntegrationSubject.next(message); }}
        ]
      }
    ];

    this._hubConnections = hubConnectionConfigs.map(this.setUpHubConnection);
  }

  setUpHubConnection(hubConnectionConfig: HubConnectionConfig) : HubConnection {
    let hubConnection = new HubConnectionBuilder().withUrl(`${environment.signalRServerURL}/${hubConnectionConfig.hubName}`)
                                                  .withAutomaticReconnect()
                                                  .build();

    hubConnectionConfig.hubMethodHandlers.forEach(item => {
      hubConnection.on(item.methodName, item.methodHandler);
    });

    hubConnection.onclose((err) => {
      hubConnectionConfig.connectionState = HubConnectionState.Disconnected;
      hubConnectionConfig.connectionStateSubject.next(HubConnectionState.Disconnected);

      console.error(err);
    });

    hubConnection.onreconnecting((err) => {
      hubConnectionConfig.connectionState = HubConnectionState.Reconnecting;
      hubConnectionConfig.connectionStateSubject.next(HubConnectionState.Reconnecting);
      
      console.log(`SignalR connection to ${hubConnectionConfig.hubName} hub is: ${hubConnectionConfig.connectionState}.`);
    });

    hubConnection.onreconnected((err) => {
      hubConnectionConfig.connectionState = HubConnectionState.Connected;
      hubConnectionConfig.connectionStateSubject.next(HubConnectionState.Connected);
      
      console.log(`SignalR connection to ${hubConnectionConfig.hubName} hub is: ${hubConnectionConfig.connectionState}.`);
    });

    return hubConnection;
  }

  isConnected() : boolean {
    return this._hubConnections.every(hubConnection => hubConnection.state == HubConnectionState.Connected);
  }

  connect() : void {
    if(!this.isConnected()){
      let promises = this._hubConnections.map(hubConnection => {
                        if(hubConnection.state != HubConnectionState.Disconnected)
                          return hubConnection.stop();
                      });

      Promise.all(promises)
            .then(_ => {
              this.orderHubConnectionState = HubConnectionState.Disconnected;
              this.orderHubConnectionStateSubject.next(this.orderHubConnectionState);

              this.integrationHubConnectionState = HubConnectionState.Disconnected;
              this.integrationHubConnectionStateSubject.next(this.integrationHubConnectionState);

              this.connectToHubs(this._hubConnections); 
            })
            .catch(err => { 
              this.startingSubject.error(err);
              console.error(`Error initializing connections to hubs. Details: ${err}.`);

              setTimeout(this.connect, RECONNECTION_TIMEOUT);
            });
    }
  }

  connectToHubs(hubConnections: Array<HubConnection>) : void {
    let promises = hubConnections.map(hubConnection => hubConnection.start());

    Promise.all(promises)
          .then(_ => {
            this.orderHubConnectionState = HubConnectionState.Connected;
            this.orderHubConnectionStateSubject.next(this.orderHubConnectionState);

            this.integrationHubConnectionState = HubConnectionState.Connected;
            this.integrationHubConnectionStateSubject.next(this.integrationHubConnectionState);

            this.startingSubject.next();
            console.log("Connections successfully established.");
          })
          .catch(err => { 
            this.startingSubject.error(err);
            console.error(`Error initializing connections to hubs. Details: ${err}.`);
          });
  }
}