import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ShoppingCartItem } from '../types/shopping-cart-item';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class PackagePlanningService {

  constructor(
    private http: HttpClient
  ) { }

  process(item: ShoppingCartItem) {
    const url = `${environment.packagePlanningAPI}/process`;
    const httpOptions = {
       headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(url, item, httpOptions);
  }
}
