import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Token } from '../types/token';
import { Account, ChangePassword, ResetPassword, User } from '../types/create-account';
import { Router } from '@angular/router';

@Injectable()
export class CreateAccountService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  registerNewUser(user_data: Account): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');    
    const requestOptions = {headers, body: user_data};    
    return this.http.post(`${environment.api}api/account`, user_data);
  }

  getAccountNumber(email: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/accountnumber`, { headers: headers, params: { email: email } });
  }

  getUserAddress(): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.get(`${environment.api}api/account/billingaddrress`, {headers: headers});
  }

  getUserAccount(): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.get(`${environment.api}api/account`, {headers: headers});
  }

  getCustomerPriceGroup( ): Observable<any> {    
    const headers = new HttpHeaders();   
    return this.http.get(`${environment.api}api/account/customerPriceGroup`, {headers: headers});
  }

  getInvoices(from: string, to:string ): Observable<any> {
    const headers = new HttpHeaders({timeout: `${60000}`});
    return this.http.get(`${environment.api}api/account/invoices`, { headers, params: { from, to},  });
  } 

  editUserAccount(user_data: Account): Observable<any> {
    const headers = new HttpHeaders();
    // const token = this.loginService.getToken();
    // headers.append('Authorization', `${token.token_type} ${token.access_token}`);
    // token.companyname = user_data.companyName;
    // localStorage.setItem('token', JSON.stringify(token));
    return this.http.put(`${environment.api}api/account`, user_data, {headers});
  }

  forgotPassword(email: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const parseEmail = email.replace('@', '%40');
    return this.http.post(`${environment.api}api/account/forgotpassword?email=${parseEmail}`, {headers});
  }

  changePassword(new_password: ChangePassword): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/account/changepassword`, new_password, {headers});
  }

  resetPassword(reset_password: ResetPassword): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/account/resetpassword`, reset_password, {headers});
  }

  getDiscoveryOptions(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/discoveryoptions`, {headers: headers});
  }

  getWebsiteAnnouncements(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/websiteannouncements`, {headers: headers});
  }

  getRolesOptions(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/customeroles`, {headers: headers});
  }

  getSalespersonCodeOptions(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/salespersoncode`, {headers: headers});
  }

  getServiceZoneCode(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/servicezonecode`, {headers: headers});
  }

  getTaxBusPostingGroup(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/taxbuspostinggroup`, {headers: headers});
  }

  getCustomerPricingGroup(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/customerpricinggroup`, {headers: headers});
  }

  getCustomerPostingGroup(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/customeroles`, {headers: headers});
  }

  getCredentialingService(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/credentialingservice`, {headers: headers});
  }

  getInvoicingPortal(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/invoicingportal`, {headers: headers});
  }

  getPalletizeOptions(): Observable<any> {
    // tslint:disable-next-line:prefer-const   
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');    
    return this.http.get(`${environment.api}api/account/palletizeoptions`, {headers: headers});
   }

   getstuckcustomer( ): Observable<any> {     
     // tslint:disable-next-line:prefer-const   
     const headers = new HttpHeaders();
     headers.append('Content-Type', 'application/json');    
     return this.http.get(`${environment.api}api/account/getstuckcustomers`, {headers: headers});
  } 

  validationLogin() {
    this.getUserAccount().subscribe( success => {
    }, error => {
      // if ( error.status === 401) {
      //   this.loginService.logOut();
      //   this.router.navigate(['login']);
      // }
    });
  }

  sendSuggestions( msj: String ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(`${environment.api}api/account/suggestion`, '"' + msj + '"', {headers});
  }

  sendCustomerMessage( customerID: number ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(`${environment.api}api/account/sendcustomermessage`, customerID, {headers});
  }

  createUser( newUser: User ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(`${environment.api}api/account/createuser`, newUser, {headers});
  }

  UpdatePasswordUser( newUser: User ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(`${environment.api}api/account/updateuser`, newUser, {headers});
  }

  DeleteUser( newUser: User ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(`${environment.api}api/account/disableuser`, newUser, {headers});
  }

  AddAccount( newUser: User ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(`${environment.api}api/account/addaccount`, newUser, {headers});
  }

  validateEmail(emailAddress: string, isEditing: string) {
    const headers = new HttpHeaders();
    // tslint:disable-next-line:prefer-const
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account/validateemail`, { headers, params: { emailAddress, isEditing} });
  }

  updateServiceZone(serviceZoneId: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/account/${serviceZoneId}`, {headers: headers});
  }

  recordSIteAudit(IPAdress: string)
  {   
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(`${environment.api}api/account/sitelogin`, {headers});
  }

  recordSIteAuditVisitor() {    
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    headers.append('Accept', 'application/json');
    return this.http.post(`${environment.api}api/account/siteloginvisitor`, {headers});
  }

  getIpAdrress(): Observable<any> {
    const headers = new HttpHeaders();
    //headers.append('Content-Type', 'application/json');
    return this.http.get(`http://api.ipify.org/?format=json`,{headers: headers});    
  } 

  getInvoicingDetails() : Observable<any> {
    let httpHeaders = new HttpHeaders({ "Accept" : "application/json" });

    return this.http.get(`${environment.api}api/account/invoicingDetails`, {headers: httpHeaders});
  }

  updateInvoicingDetails(invoicingDetails) : Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');

    return this.http.put(`${environment.api}api/account/invoicingDetails`, invoicingDetails, {headers: httpHeaders});
  }   
}