import { Injectable } from '@angular/core';
import { ScreenSize } from '../types/screen-size';
import { BehaviorSubject, Observable } from 'rxjs';

const WIDTH_SMALL_DEVICES = 600;

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // Handle behavior
  private sizeScreenHandler = new BehaviorSubject<ScreenSize>({ });

  // Getting screen changes
  public readonly currentScreen: Observable<ScreenSize> = this.sizeScreenHandler.asObservable();

  constructor() { }

  onSaveScreenSizeChange(screenWidth: number, screenHeight: number) {
    this.sizeScreenHandler.next({
      width: screenWidth,
      height: screenHeight,
      isStandardDevice: screenWidth ? screenWidth > WIDTH_SMALL_DEVICES : true
    })
  }
}
