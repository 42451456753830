import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
// Types
import { AlertNotify } from '../types/alert-Notify';
// Environment
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private http: HttpClient
  ) { }

  getAlertsByUser(customer: string, user: string): Observable<AlertNotify[]> {
    return this.http.get<AlertNotify[]>(`${environment.api}api/alerts/GetAlertsByUser`, { 
      params: {
        customerOwner: customer,
        userLogin: user
      } 
    });
  }

  putMarkReadAlert(model: AlertNotify): Observable<AlertNotify> {
    return this.http.put<AlertNotify>(`${environment.api}api/alerts/MarkReadAlert`, model);
  }
}
