import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Token } from '../types/token';
import { Fedex } from '../types/fedex';

@Injectable()
export class FedexService {

  constructor(
    private http: HttpClient
  ) { }

  getAddress(address: Fedex): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/fedexservices/validations/addresses`, address, {headers: headers});
  }

}
