import { BehaviorSubject } from 'rxjs';
import { ExpeditedProductionCapacityOption, ExpeditedProductionCapacityRequest, ExpeditedProductionCapacityResponse } from '../types/shopping-cart';

export class ExpeditedService {
  private expeditedCapacityResponse = new BehaviorSubject<ExpeditedProductionCapacityOption[]>([]);

  expeditedRequest$ = this.expeditedCapacityResponse.asObservable();

  updateExpeditedRequest(request: ExpeditedProductionCapacityOption[]) {
    this.expeditedCapacityResponse.next(request);
  }
}
