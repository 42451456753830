import { Component, ViewChild, ViewContainerRef, Output, EventEmitter, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  exportAs: 'bs-modal'
})
export class ModalComponent {
  @ViewChild('childModal') public childModal: ModalDirective;
  @Output() closed = new EventEmitter();
  @Output() opened = new EventEmitter();
  @Input() isHeighter: string = null;
  @Input() isNarrow: string = null;
  @Input() centered: boolean = null;
  constructor() {
  }

  public showChildModal(): boolean {
    if(!this.childModal.config)
      this.childModal.config = {};

    this.childModal.config.backdrop = false;
    this.childModal.show();
    return true;
  }

  public hideChildModal(): boolean {
    this.childModal.hide();
    return false;
  }

  emitHidden(event: any) {
    this.closed.emit(true);
  }

  emitOpened(event: any) {
    this.opened.emit(true);
  }

  emitHide(event: any){
    // this.childModal.isShown
    var ifrm = document.querySelector("iframe");
    if(ifrm){
      ifrm.src = "";
    }
  }
}
