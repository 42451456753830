import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public slogan = 'OPEN THE DOOR TO EASY';
  public phone = '844-4-THE-KIT (844-484-3548)';

  constructor() { }

  ngOnInit() { }

}
