import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ShoppingCartService } from '../shared/services/shopping-cart.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-ledge-modal',
  templateUrl: './ledge-modal.component.html',
  styleUrls: ['./leadge-modal.component.scss']
})
export class LedgeModalComponent implements OnInit {
  @Output() tableLedge: EventEmitter<any> = new EventEmitter<any>();
  @Input('title') title;
  @Input('jsonLedge') jsonLedge;
  @Input('total') total;

  public invoices: any;
  @BlockUI() blockUi: NgBlockUI;

  constructor(
    private shoppingCartService: ShoppingCartService) {
  }

  ngOnInit() {
  }

  async downloadInvoice(PSINo: any) {
    this.blockUi.start('Downloading Invoice');
    this.invoices = await this.shoppingCartService.getInvoice(PSINo.docNumber);
    this.blockUi.stop();
  }

  shouldDisplayToolTip(content: string, size: number): boolean {
    return content && content.length > size;
  }
}
