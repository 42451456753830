import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Token } from '../types/token';
import { DoorStyle } from '../types/style';


@Injectable()
export class StyleService {
  constructor(
    private http: HttpClient) { }

  getDoorStyle(colorID: string): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['colorID'] = colorID;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptions = {headers, params: searchParams};
    return this.http.get<Array<DoorStyle>>(`${environment.api}api/doorstyles`, requestOptions);
  }

  getDoorStyleByColorAndConstruction(colorID: string, doorConstructionID: string): Observable<any> {
    let searchParams: any = new Object();
    searchParams['colorID'] = colorID;
    searchParams['doorConstructionID'] = doorConstructionID;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptions = {headers, params: searchParams};
    return this.http.get<Array<DoorStyle>>(`${environment.api}api/doorstyles/colorandconstruction`, requestOptions);
  }
}
