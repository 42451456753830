import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Token } from '../types/token';

@Injectable()
export class ColorService {

  constructor(
    private http: HttpClient
  ) { }

  getColors(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/colors`, {headers: headers});
  }

  getColorsByStyle(doorStyleId: string): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['styleId'] = doorStyleId;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}api/colors/colorsbystyle`, requestOptiosn);
  }

  getColorsByDoorConstructionID(doorConstructionId: number): Observable<any> {
    let searchParams: any = new Object();
    searchParams['doorConstructionID'] = doorConstructionId;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}api/colors/colorsbyconstruction`, requestOptiosn);
  }
}
