class OrderEntryType {
    id: string
    type: string
    screenSize: string
}

enum OrderEntryTypeType {
    CreateUnitsFromSpreadsheet = "CreateUnitsFromSpreadsheet",
    BeginOrderOrQuote = "BeginOrderOrQuote",
    CreateNoChargeOrder = "CreateNoChargeOrder",
    OrderSimilar = "OrderSimilar",
    Unknown = "Unknown"
}

export { OrderEntryType, OrderEntryTypeType };