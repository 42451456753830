import { Injectable } from '@angular/core';

@Injectable()
export class CapitalizeService {

  constructor() { }
  capitalize(value: string): string {
    const stringArray = value.split(' ');

    stringArray.forEach((str, index) => {
      stringArray[index] = str.charAt(0).toUpperCase() + str.slice(1);
    });

    return stringArray.join(' ');
  }
  ToSentenceCase(value: string): string {
    const stringArray = value.split(' ');

    stringArray.forEach((str, index) => {
      stringArray[index] = `${ str.charAt(0).toUpperCase() }${ str.slice(1).toLowerCase() }`
    });

    return stringArray.join(' ');
  }
}
