import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Token } from '../types/token';
import { StandarItems } from '../types/cabinet-skins';
import { PrimerAndPaint } from '../types/primer-and-paint';

@Injectable()
export class CabinetSkinsService {

  constructor(
    private http: HttpClient
  ) { }

  getStandarItems(aptID: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.get(`${environment.api}api/apartments/${aptID}/standarditems`, {headers: headers});
  }

  saveStandarItem(standarItem: StandarItems): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/apartments/standarditems`, standarItem, {headers: headers});
  }

  checkBelongCustomItem(apartmentiId: number, pullItenmNumber: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/customitems/checkbelongcustomitem`, {headers, params: {apartmentId: apartmentiId.toString(), pullItemNumber: pullItenmNumber}});
  }

  getPrimerAndPaint(aptID: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http
      .get<Array<PrimerAndPaint>>(`${environment.api}api/apartments/${aptID}/standarditems/primersandpaints`, {headers: headers});
    }

  savePrimerAndPaint(primer: PrimerAndPaint) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/apartments/standarditems/primersandpaints`, primer, {headers: headers});
  }

  getMissingStandarItems(aptID: number): Observable<any> {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.api}api/apartments/${aptID}/missingstandarditems`, {headers: headers});
  }

  removePullDrill(apartmentId: number, pullItemNumber: string):Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentId'] = apartmentId.toString();
    searchParams['pullItemNumber'] = pullItemNumber;
    const requestOptions = {headers, params: searchParams };
    return this.http.put(`${environment.api}api/apartments/removepulldrill`,  '', requestOptions);
  }
}
