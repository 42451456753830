import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Token } from '../types/token';
import { DoorConstruction } from '../types/door-construction';


@Injectable()
export class DoorConstructionService {
  constructor(
    private http: HttpClient) { }

    getDoorConstructions(): Observable<any> {   
  
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptions = {headers};
    return this.http.get<Array<DoorConstruction>>(`${environment.api}api/doorconstructions`, requestOptions);
  }
}